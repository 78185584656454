<template>
	<div v-loading="loading">
		<div style="background: #fff;padding: 15px;margin-top: 10px;position: relative;">
			<div style="color: #303133;font-size: 14px;">等级概况:</div>
			<div style="display: flex;margin-top: 30px;">
				<div v-for="(item,index) in vipList" :key="index" style="width: 100px;margin-right: 50px;">
					<div style="color: #101010;font-size: 14px;text-align: center;">{{item.VipRoleName }}</div>
					<div style="color: #909399;font-size: 12px;text-align: center;margin-top: 10px;">{{item.MemberCount}}</div>
				</div>
			</div>
			
			<div style="position: absolute;right: 20px;top: 10px;">
				<experience-code></experience-code>
			</div>
		</div>
		<div style="background: #fff;padding: 15px;margin-top: 10px;">
			<buttonPermissions :datas="'setWxCard'">
				<div style="display: flex;">
					<div style="color: #303133;font-size: 14px;white-space: nowrap;">微信会员卡同步配置：</div>
					<div>
						<el-switch v-model="settingValue" active-color="#409EFF" inactive-color="#DCDFE6" @change="changeWei">
						</el-switch>
						<div v-if="settingValue" style="flex: 1;">
							<div style="margin-top:20px;display: flex;align-items: center;">
								<div style="width: 85px;text-align: right;color: #303133;font-size: 14px;">会员卡背景：</div>
								<img :src="imgUrl+WxVipCardInfo.BackgroundPicUrl" alt="" style="width: 115px;height: 40px;" v-if="WxVipCardInfo.BackgroundPicUrl">
								<div v-else>--</div>
								<div style="position: absolute;right: 300px;">
									<buttonPermissions :datas="'editWxCard'">
										<div style="color: #409EFF;font-size: 14px;cursor: pointer;" @click="editVipModel" v-if="WxVipCardInfo.CheckState!=0 ||WxVipCardInfo.Id==0">编辑卡套信息</div>
									</buttonPermissions>
									<div style="margin-top: 5px;color: #F56C6C;font-size: 14px;" v-if="WxVipCardInfo.Id">{{WxVipCardInfo.CheckStateValue}}</div>
								</div>
							</div>
							<div style="color: #303133;font-size: 14px;display: flex;margin-top: 20px;align-items: center;">
								<div style="width: 85px;text-align: right;">会员卡标题：</div>
								<span v-if="WxVipCardInfo.Title">{{WxVipCardInfo.Title}}</span>
								<div v-else>--</div>
							</div>
							<div style="display: flex;margin-top: 20px;">
								<div style="width: 85px;text-align: right;color: #303133;font-size: 14px;">使用须知：</div>
								<div style="width: 800px;color: #909399;font-size: 14px;" v-if="WxVipCardInfo.Prerogative">{{WxVipCardInfo.Prerogative}}</div>
								<div v-else>--</div>
							</div>

						</div>
					</div>
				</div>
			</buttonPermissions>
		</div>

		<div style="background: #fff;padding: 15px;margin-top: 10px;">
			<el-table :data="vipData" style="width: 100%;" v-loading="loading">
				<el-table-column prop="VipLevel" label="级别">
					<template slot-scope="scope">
						<div v-if="scope.row.Id>0" style="color: #333;">
							VIP{{scope.row.VipLevel}}
						</div>
						<div v-else style="color: #999;">
							VIP{{scope.row.VipLevel}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="VipRoleName" label="级别名称">
					<template slot-scope="scope">
						<div v-if="scope.row.Id>0" style="color: #333;">
							<div v-if="scope.row.VipRoleName">{{scope.row.VipRoleName}}</div>
							<div v-else>--</div>
						</div>
						<div v-else style="color: #999;">
							<div v-if="scope.row.VipRoleName">{{scope.row.VipRoleName}}</div>
							<div v-else>--</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="Upgrade" label="升级条件">
					<template slot-scope="scope">
						<div v-if="scope.row.Id>0" style="color: #333;">
							<div v-if="scope.row.Upgrade">{{scope.row.Upgrade}}</div>
							<div v-else>--</div>
						</div>
						<div v-else style="color: #999;">
							<div v-if="scope.row.Upgrade">{{scope.row.Upgrade}}</div>
							<div v-else>--</div>
						</div>
					</template>

				</el-table-column>
				<el-table-column prop="level" label="级别权益">
					<template slot-scope="scope">
						<div v-if="scope.row.Id" style="color: #333;">
							<div v-if="scope.row.VipDiscount&&scope.row.VipDiscount!=10">消费{{scope.row.VipDiscount}}折</div>
							<div v-else>--</div>
							<div v-if="scope.row.IsFreeExpress">包邮</div>
							<div v-if="!scope.row.IsFreeExpress">不包邮</div>
							<div v-if="scope.row.PointMultiple&&(scope.row.PointMultiple!=1)">积分{{scope.row.PointMultiple}}倍</div>
							<div v-else>--</div>
						</div>
						<div v-else style="color: #999;">--</div>
					</template>
				</el-table-column>
				<el-table-column prop="level" label="升级礼包">
					<template slot-scope="scope">
						<div v-if="scope.row.Id" style="color: #333;">
							<div v-if="scope.row.CurrentAuthGivePoint">赠送{{scope.row.CurrentAuthGivePoint}}积分</div>
							<div v-else>--</div>
							<div v-if="scope.row.CouponCount">赠送{{scope.row.CouponCount}}张优惠券</div>
							<div v-else>--</div>
							<div v-if="scope.row.GiftCount">赠送{{scope.row.GiftCount}}件商品</div>
							<div v-else>--</div>
						</div>
						<div v-else style="color: #999;">--</div>
					</template>

				</el-table-column>
				<el-table-column label="背景">
					<template slot-scope="scope">
						<div v-if="scope.row.BackgroupType==1&&scope.row.BackgroupImg">
							<img :src="imgUrl+scope.row.BackgroupImg" alt="" style="width: 115px;height: 40px;">
						</div>
						<div v-if="scope.row.BackgroupType==0&&scope.row.BackgroupColor" :style="'width: 115px;height: 40px;'+'background:'+scope.row.BackgroupColor"></div>
						<div v-if="!scope.row.BackgroupImg && !scope.row.BackgroupColor">
							<span v-if="scope.row.Id==0" style="color: #999;">--</span>
							<span v-else style="color: #333;">--</span>
						</div>
					</template>
				</el-table-column>
				<buttonPermissions :datas="'editCard'">
					<el-table-column label="操作" width="140" :key='7'>
						<template slot-scope="scope">
							<div>
								{{scope.row.haveStop}}
								<el-button type="text" @click="eidtGroup(scope.row,scope.$index)" v-if="scope.$index<=(MembershipList.length)"><span>{{scope.row.Id==0 ? '启用' : '编辑'}}</span></el-button>
								<el-button type="text" style="color:#f56c6c" @click="del(scope.row)" v-if="scope.row.VipLevel==maxVipLevel">停用</el-button>
							</div>
						</template>
					</el-table-column>
				</buttonPermissions>
			</el-table>
		</div>

		<!-- 请完善微信卡套信息 -->
		<el-dialog title="请完善微信卡套信息" :visible.sync="isShowEditModel" width="600px">
			<el-form :model="form" ref="form" :rules="rules">
				<el-form-item label="会员卡背景:" label-width="100px" prop="BackgroundPicUrl">
					<div style="display: flex;">
						<img v-if="form.BackgroundPicUrl" :src="imgUrl+form.BackgroundPicUrl" alt="" style="width: 115px;height: 40px;margin-right:10px;">
						<el-upload style="width: 135px;" :action="imgApi" :on-success="handleAvatarSuccessfu" :file-list="fileList"
						 multiple accept="image/png,image/jpeg,image/jpg,image/gif" :on-remove="handleRemovefu" :show-file-list="false">
							<el-button type="text" size="small" style="margin-top:5px;font-size: 14px;" v-if="form.BackgroundPicUrl">更改图片</el-button>
							<el-button type="text" size="small" style="margin-top:5px;font-size: 14px;" v-if="!form.BackgroundPicUrl">上传图片</el-button>
						</el-upload>

						<div style="line-height: 25px;margin-left: 20px;font-size: 12px;color: #C0C4CC;">
							<div>1. 尺寸:1000*1600像素</div>
							<div>2.大小:1M以内</div>
							<div>3.格式：jpg、png、jpeg</div>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="会员卡标题 :" label-width="100px" prop="Title">
					<input type="text" style="width:400px;height: 30px;padding-left:10px;border:1px solid #ccc" placeholder="最多输入9个汉字或18个英文字母"
					 v-model="form.Title" :maxlength="maxLength" class="edit-input" ref="Title" @input="changeValue">
					<!-- <el-input clearable auto-complete="on" placeholder="最多输入9个汉字或18个英文字母" v-model="form.Title" style="width: 400px;"></el-input> -->
				</el-form-item>
				<el-form-item label="使用须知 :" label-width="100px" prop="Prerogative">
					<el-input auto-complete="on" :rows="5" type="textarea" v-model="form.Prerogative" maxLength="300" style="width: 400px;"></el-input>
				</el-form-item>
			</el-form>
			<div style="padding-top: 20px;">
				<el-button style="width: 120px;margin-left: 130px;" @click="cancelSave">关 闭</el-button>
				<el-button type="primary" @click="submitSave('form')" style="width: 120px;margin-left: 30px;">提交微信审核</el-button>
			</div>
		</el-dialog>
		<!-- 保存提示 -->
		<el-dialog :visible.sync="isShowCommit" width="350px" center :show-close="false">
			<div style="line-height: 20px;">编辑微信会员卡卡套信息，需要3-5个工作日审核，审核通过前，微信卡套信息不会发生变化。是否确认提交审核？</div>
			<span slot="footer" class="dialog-footer">
				<el-button style="width: 100px;" @click="isShowCommit=false">关 闭</el-button>
				<el-button type="primary" style="width: 100px;margin-left: 30px;" @click="submitWeiChat">提交审核</el-button>
			</span>
		</el-dialog>


		<!-- //3.1.0新增需求 -->
		<div style="background: #fff;padding: 15px;margin-top: 10px;margin-bottom: 120px;padding-bottom: 30px;position: relative;">
			<el-button type='primary' style="position: absolute;right: 30px;bottom: 20px;" @click='clickgoodlist'>保存</el-button>
			<div style="border-bottom: 1px solid #F0F2F5;padding-bottom: 15px;">会员页商品推荐</div>
			<div style="font-size: 14px;color: #999;margin-top: 15px;">添加商品后，将会展示在会员权益页 <span style="color:#409EFF;cursor: pointer;margin-left:25px;"
				 @click="lookShow">查看示例</span></div>
			<div style="display: flex;margin-top: 15px;align-items: center;font-size: 14px;color: #999;">
				<span>最多添加10个商品；拖拽已选择商品可排序</span>
				<div @click="chosegoods" v-if='selectedList.length<10' style="color:#409EFF;cursor: pointer;margin-left: 25px;">添加商品</div>
			</div>

			<div class="list-wrap" style="margin-top: 20px;">
				<draggable style='width:80%;display: flex;flex-wrap: wrap;' class="draggablebox" v-model="selectedList" :options="{
						animation: 150,
						ghostClass: 'sortable-ghost',
						chosenClass: 'chosenClass',
						scroll: true,
						scrollSensitivity: 200
					}">
					<div class="tab-item" v-for="(item,index) in selectedList" :key="index" style="margin-bottom:15px;margin-right:30px;width:120px;display: flex;flex-direction: column;align-items: center;">
						<div class="tab-edit" style="display: flex;flex-direction: column;width: 100%;position: relative;">
							<img style="width:100%;margin-bottom: 10px;height: 120px;" v-if="item.ImgUrl" :src="imgUrl + item.ImgUrl" class="upload-avatar" />
							<i class="el-icon-error" style="position: absolute;top: 0px;right: 0px;" @click="handleDeleteNav(index)"></i>
						</div>
					</div>

				</draggable>
			</div>
		</div>
		<el-dialog title="示例图" :visible.sync="lookVisable" width="800px">
			<div style="text-align: center;">
				<img :src="lookPicture" style="width:400px;"/>
			</div>
		</el-dialog>
		<!-- //选择商品弹窗 -->
		<el-dialog title="选择商品" :visible.sync="addProductDialogShow" width="1300px" class="dialog">
			<select-recomproduce :params="['KeyWord', 'ProductType', 'ProductGroupIds', 'ProductBrandId']" :goodsMaxlength='10'
			 :selectedData="selectedList" @getSelectproList="getSelectproList" :visible.sync="addProductDialogShow" v-if="addProductDialogShow">
			</select-recomproduce>
		</el-dialog>
	</div>
</template>

<script>
	import {
		memberVipRoleIndex,
		changeisopenwxvipcard,
		wxvipcardCreatevipcard
	} from '@/api/goods'
	import {
		memberPageProductSave,
		memberPageProductList,
		memberVipRoledeleteMemberVipRole
	} from '@/api/TurnTomySelf'
	import selectRecomproduce from '../goods/selectgoodsElement.vue'
	import config from '@/config/index'
	import buttonPermissions from '@/components/buttonPermissions';
	import draggable from "vuedraggable";
	
	import experienceCode from '@/components/experienceCode';
	
	export default {
		components: {
			buttonPermissions,
			selectRecomproduce,
			draggable,
			experienceCode
		},
		data() {
			return {
				//3.1.0新加
				selectedList: [],
				addProductDialogShow: false,
				options: [{
					value: 'HTML',
					label: 'HTML'
				}, {
					value: 'CSS',
					label: 'CSS'
				}, {
					value: 'JavaScript',
					label: 'JavaScript'
				}],
				lookVisable: false,
				lookPicture: '',
				//结束
				vipList: [],
				maxLength: 9,
				form: {
					BackgroundPicUrl: '',
					Prerogative: '',
					Title: ''
				},
				imageUrl: '',
				imageList: [],
				fileList: [],
				settingValue: false,
				vipData: [],
				loading: false,
				isShowEditModel: false,
				isShowCommit: false,
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				rules: {
					BackgroundPicUrl: [{
						required: true,
						message: '请选择会员卡背景',
						trigger: 'change'
					}],
					Title: [{
							required: true,
							message: '请输入会员卡标题',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 18,
							message: '长度在 1 到 18 个字符',
							trigger: 'blur'
						}
					],
					Prerogative: [{
							required: true,
							message: '请输入使用须知',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 300,
							message: '使用须知最多可输入300字',
							trigger: 'blur'
						}
					],
				},
				WxVipCardInfo: {},
				MembershipList: [],

				maxVipLevel:0,
			};
		},
		beforeMount() {
			this.getgoodlist()
			this.getDatas()
		},
		methods: {
			del(row){
				this.$confirm('停用会员等级将对已是该等级的会员造成影响，请提前发布公告，以避免造成不必要客诉。已停用的等级，后续可重新启用。是否确认停用？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
					this.confirmdel(row)
				}).catch(()=>{})
			},
			async confirmdel(row){
				this.loading = true
				try{
					let msg = await row
					let data = {
						Id:msg.Id,
						noError:true
					}
					let res= await memberVipRoledeleteMemberVipRole(data)
					if(res.IsSuccess){
						this.$message.success('操作成功')
						this.getDatas()
					}else{
						if(res.ErrorCode==2000014){
							this.$confirm('停用失败，该等级下存在会员，请先前往「客户查询」中修改对应会员等级。', '提示', {
								confirmButtonText: '前往修改',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(()=>{
								this.$router.push({
									name:'CustomerSearch',
									params:{
										vipId:msg.Id
									}
								})
							}).catch(()=>{})
						}
					}
				}finally{
					this.loading = false
				}
			},
			//3.1.0新加
			async getgoodlist() {
				try {
					let result = await memberPageProductList()
					this.selectedList = result.Result
					// console.log(result,'初始化数据')
				} catch (err) {

				} finally {

				}
			},
			//保存推荐商品
			async clickgoodlist() {
				try {
					// if (!this.selectedList.length) {
					// 	this.$message({
					// 		showClose: true,
					// 		type: 'error',
					// 		message: '请至少选择一种商品'
					// 	});
					// 	return
					// }
					this.selectedList.forEach((item, index) => {
						item.SortIndex = index
					})
					let data = {
						ProductList: this.selectedList
					}
					let result = await memberPageProductSave(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '保存成功'
						});
						this.getDatas()
					}
					console.log(this.selectedList, '被选中的商品')
				} catch (err) {

				} finally {

				}

			},
			//选择商品
			chosegoods() {
				this.addProductDialogShow = true;
			},
			//删除商品
			handleDeleteNav(index) {
				this.selectedList.splice(index, 1)
				this.$forceUpdate()
			},
			getSelectproList(data) {
				this.addProductDialogShow = false
				this.selectedList = data
			},
			// 查看示例
			lookShow() {
				this.lookPicture = 'https://cdn.dkycn.cn/Images/viplevel.png'

				this.lookVisable = true
			},
			//结束
			validateTextLength(value) {
				// 中文、中文标点、全角字符按1长度，英文、英文符号、数字按0.5长度计算
				let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g
				let mat = value.match(cnReg)
				let length
				if (mat) {
					length = (mat.length + (value.length - mat.length) * 0.5)
					return length
				} else {
					return value.length * 0.5
				}
			},
			changeValue() {
				let leng = this.validateTextLength(this.form.Title)
				if (leng >= 9) {
					this.$refs.Title.maxLength = leng
				} else {
					this.$refs.Title.maxLength = 18
				}
			},

			// 获取数据
			async getDatas() {
				try {
					this.loading = true
					let result = await memberVipRoleIndex()
					let vipList = result.Result.MembershipList
					this.MembershipList = JSON.parse(JSON.stringify(result.Result.MemberVipRoleList));
					this.vipData = JSON.parse(JSON.stringify(result.Result.MemberVipRoleList));
					this.settingValue = result.Result.IsOpenWxVipCard
					this.WxVipCardInfo = result.Result.WxVipCardInfo
					let allCount = 0;
					this.maxVipLevel = Math.max(...vipList.map(v=>v.VipLevel))
					vipList.map(item => {
						allCount = allCount + item.MemberCount

						item.haveStop = false
					})
					let obj = {
						VipRoleName: '全部会员',
						MemberCount: allCount
					}
					this.vipList = vipList
					this.vipList.unshift(obj)

					// console.log(this.vipList)
					let vipLength = this.vipData.length
					for (var i = 1; i <= 5 - (vipLength); i++) {
						let obj = {
							VipLevel: vipLength + i,
							BackgroupColor: '',
							BackgroupImg: "",
							BackgroupType: null,
							CouponCount: '',
							CurrentAuthGivePoint: '',
							GiftCount: null,
							Id: 0,
							IsFreeExpress: false,
							PointMultiple: '',
							Upgrade: "",
							VipDiscount: '',
							VipRoleName: ""
						}
						this.vipData.push(obj)
					}
					// console.log(this.vipData)
				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false;
				}

			},

			// 提交审核
			async submitWeiChat() {
				try {
					let result = await wxvipcardCreatevipcard(this.form)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功'
						});
						this.isShowCommit = false;
						this.isShowEditModel = false;
					}


				} catch (e) {
					console.log(e)
				} finally {
					this.getDatas()
				}

			},
			//微信是否同步
			async changeWei() {
				try {
					let result = await changeisopenwxvipcard()


				} catch (e) {
					console.log(e)
				} finally {

				}

			},
			eidtGroup(record, index) {

				this.$router.push({
					path: '/Customer/vipEdit',
					query: {
						Id: record.Id ? record.Id : 0,
						VipLevel: index + 1
					}
				});

			},
			editVipModel() {
				this.getDatas()
				this.form = this.WxVipCardInfo
				this.isShowEditModel = true
			},
			cancelSave() {
				this.isShowEditModel = false
			},

			//上传图片
			handleAvatarSuccessfu(res, file) {

				if (['image/png', 'image/jpeg', 'image/jpg', 'image/jpeg', 'image/gif', 'image/PNG', 'image/JPG', 'image/JPEG',
						'image/GIF'
					].indexOf(file.raw.type) == -1) {
					this.$message.error('请上传正确的图片格式');
					return false;
				}
				const isLt1M = file.size / 1024 / 1024 < 1;
				if (!isLt1M) {
					this.$message.error('上传图片大小不能超过1MB哦!');
					return false;
				}


				this.form.BackgroundPicUrl = file.response[0]
				this.imageList.push(file.response[0])


			},
			handleRemovefu(file, fileList) {

				this.imageList = this.imageList.filter(item => {
					return item != file.response[0]
				})

				console.log(this.imageList, 'kdhahdja')
			},
			submitSave(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.isShowCommit = true
					} else {
						console.log('error submit!!');
						return false;
					}
				});

			}
		}
	}
</script>

<style lang="less" scoped>
	.el-upload--picture-card {
		width: 135px !important;
		height: 30px !important;
		border: 1px solid #ddd;
		background: #fff !important;
		line-height: 29px !important;
	}

	.draggablebox {

		.tab-item {
			margin-bottom: 15px;
			// &:nth-child(5n){
			// 	margin-right: 0px!important;
			// }
		}
	}
</style>
